// Application initialization
(function($) {
    'use strict';

    // Initialize when document is ready
    $(document).ready(function() {
        // Initialize AWS configuration if SDK is loaded
        if (window.APP_CONFIG && typeof window.AWS !== 'undefined') {
            window.AWS.config.region = window.APP_CONFIG.AWS_REGION;
            window.AWS.config.credentials = new window.AWS.CognitoIdentityCredentials({
                IdentityPoolId: window.APP_CONFIG.IDENTITY_POOL_ID
            });
        }

        // Initialize UI components
        if (typeof feather !== 'undefined') {
            feather.replace();
        }

        // Initialize tooltips if Bootstrap is loaded
        if (typeof $.fn.tooltip !== 'undefined') {
            $('[data-bs-toggle="tooltip"]').tooltip();
        }

        // Initialize popovers if Bootstrap is loaded
        if (typeof $.fn.popover !== 'undefined') {
            $('[data-bs-toggle="popover"]').popover();
        }

        // Initialize sidebar
        $('#vertical-menu-btn').on('click', function() {
            $('body').toggleClass('sidebar-enable');
            if ($(window).width() >= 992) {
                $('body').toggleClass('vertical-collpsed');
            }
        });

        // Handle logout
        $('a[href="auth-logout.html"]').on('click', async function(e) {
            e.preventDefault();
            try {
                if (window.awsBundle) {
                    await window.awsBundle.logout();
                }
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = '/auth-login.html';
            } catch (error) {
                console.error('Logout failed:', error);
                window.location.href = '/auth-login.html';
            }
        });
    });

})(jQuery);
